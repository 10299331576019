import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About";
import Team from "./pages/Team";
import Contact from "./pages/Contact";
import SelectedInvestments from "./pages/SelectedInvestments";
import SelectedManagers from "./pages/SelectedManagers";
import SelectedTransactions from "./pages/SelectedTransactions";
import Compliance from "./pages/Compliance";
import Disclaimer from "./pages/Disclaimer";

const AppRoutes = () => {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        {/* <Route path="/about/overview" element={<Overview />} /> */}
        <Route
          path="/about/selected-investments"
          element={<SelectedInvestments />}
        />
        <Route
          path="/about/selected-transactions"
          element={<SelectedTransactions />}
        />
        <Route path="/about/selected-managers" element={<SelectedManagers />} />
        <Route path="/team" element={<Team />} />
        <Route path="/compliance" element={<Compliance />} />
        <Route path="/disclaimer" element={<Disclaimer />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
    </div>
  );
};

export default AppRoutes;
