import React from "react";
import Layout from "../components/Layout/Layout";

const Disclaimer = () => {
  return (
    <div className="Disclaimer">
      <Layout>
        <div className="content">
          <h2>Disclaimer</h2>
          <p>
            Access to the information contained herein may be restricted by laws
            and regulations applicable to the user and the user should therefore
            ensure that the use of this information and the making of any
            investment as a result does not contravene any such restrictions. In
            particular, this information does not constitute an offer to sell or
            the solicitation of any offer to buy any securities in any
            jurisdiction including the United States of America nor for the
            benefit of United States persons.
          </p>
          <p>
            No funds disclosed in this web site (collectively the "Funds") have
            been nor will be registered or qualified under the securities laws
            of the United States or any other jurisdiction. The Funds are
            unregulated collective investment schemes for the purposes of the
            United Kingdom Financial Services and Markets Act 2000 (the "Act")
            and may only be promoted in the United Kingdom in accordance with
            the Promotion of Collective Investment Schemes (Exemptions) Order
            2001.
          </p>
          <p>
            Prospective investors should not construe any of the contents of
            this web site as legal, tax or financial advice. All prospective
            investors should consult their own professional advisers as to the
            legal, tax, financial or other matters relevant to the suitability
            of an investment in the Funds for such investor.
          </p>
          <p>
            Past performance is not necessarily a guide to the future. The value
            of your investment can go down as well as up and an investor may not
            get back the amount originally invested.
          </p>
          <p>
            Whilst all reasonable steps have been taken to ensure the accuracy
            of the information provided, EFP Capital LLP has no liability to any
            persons for any errors or omissions.
          </p>
          <p>
            EFP Capital LLP is authorised and regulated by the Financial Conduct
            Authority.
          </p>
        </div>
      </Layout>
    </div>
  );
};

export default Disclaimer;
