import React from "react";
import Layout from "../components/Layout/Layout";
import "./Home.css";

const Home = () => {
  return (
    <div className="Home">
      <Layout>
        <div
          className="hero"
          style={{
            backgroundImage: "url('/images/home.jpg')",
          }}
        >
          <div className="cover">
            <div className="cover-content">
              <h2>Welcome to EFP Capital</h2>
            </div>
          </div>
        </div>
      </Layout>
    </div>
  );
};

export default Home;
