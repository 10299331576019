import React from "react";
import { Link } from "react-router-dom";
import "./Footer.css";

const Footer = () => {
  return (
    <div className="Footer">
      <p>&copy; Copyright - EFP Capital LLP {new Date().getFullYear()}</p>
      <p>
        EFP Capital LLP is Authorised and Regulated by the Financial Conduct
        Authority
      </p>
      <div>
        <Link to="/disclaimer">Disclaimer</Link> /{" "}
        <a
          href="/EFP Pillar 3 Disclosure 2021 final March 2022.pdf"
          target="_blank"
        >
          Disclosure
        </a>
      </div>
    </div>
  );
};

export default Footer;
