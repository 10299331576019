import React from "react";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import "./Layout.css";

const Layout = ({ children }) => {
  return (
    <div className="Layout">
      <Header />
      {children}
      <div className="bottom-line"></div>
      <Footer />
    </div>
  );
};

export default Layout;
