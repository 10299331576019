import React from "react";
import Layout from "../components/Layout/Layout";
import "./SelectedTransactions.css";

const SelectedTransactions = () => {
  return (
    <div className="SelectedTransactions">
      <Layout>
        <div
          className="hero"
          style={{ backgroundImage: "url('/images/transactions.jpg')" }}
        >
          <div className="cover">
            <div className="cover-content">
              <h2>Selected EFP Capital Transactions</h2>
            </div>
          </div>
        </div>
        <div className="content">
          <h2>Selected Transactions</h2>
          <div className="transaction-article">
            <h4>J. Sainsbury Sale &amp; Leaseback</h4>
            <li>
              EFP structured a £850m sale and leaseback transaction for J.
              Sainsbury PLC.
            </li>
            <li>
              The transaction had an innovative tax optimized structure which
              resulted in an efficient cost of funding for Sainsbury.
            </li>
            <h4>Cheyne Investment Grade CDO</h4>
            <li>
              Working with Cheyne Capital, EFP structured and raised the equity
              capital for a $4.6bn synthetic CDO.
            </li>
            <li>
              The Investment was designed to provide returns of 20% p.a. with a
              robust downside, protecting capital to over 4 limes historical
              default levels.
            </li>
          </div>
          <div className="transaction-article">
            <h4>Korean Downward Re-Settable Warrants</h4>
            <li>
              Working with ADM, the Hong Kong based distressed manager, EFP
              structured a portfolio of downward re¬settable Korean warrants.
            </li>
            <li>
              This was achieved through 5-10 year warrants where the strike
              reset in the event the stock price fell. The structure offered a
              superior risk/reward profile with a long term option period.
            </li>
          </div>
          <div className="transaction-article">
            <h4>Multi Asset Class Fund</h4>
            <li>
              EFP, together with Cheyne Capital, structured a fund which
              incorporated four of Cheyne's individual funds.
            </li>
            <li>
              The structure was designed to create a fund of funds type
              structure without the additional layer of fees where fees were
              netted and payable every three years.
            </li>
          </div>
          <div className="transaction-article">
            <h4>RAB Diversified Commodities Fund</h4>
            <li>
              EFP together with RAB Capital structured this fund which was
              designed to provide access to RAB's commodity funds with a
              capacity of $250m.
            </li>
            <li>
              This fund included four of RAB's commodities funds, two of which
              were closed and had fee netting amongst all funds.
            </li>
          </div>
          <div className="transaction-article">
            <h4>Galaxy China Deep Value Fund</h4>
            <li>
              Working with Galaxy Asset Management, a well-established China
              fund manager based in Hong Kong, EFP structured the Galaxy China
              Deep Value Fund (DVF).
            </li>
            <li>
              The DVF was a 5-year closed end fund, investing in Greater China.
              The DVF focused on opportunities with less liquidity or price
              transparency with higher targeted returns.
            </li>
            <li>
              EFP supported Galaxy in capital raising to AUM of over $1bn.
            </li>
          </div>
        </div>
      </Layout>
    </div>
  );
};

export default SelectedTransactions;
