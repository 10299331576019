import React, { useState } from "react";
import { Link } from "react-router-dom";
import { HashLink as NavLink } from "react-router-hash-link";
import "./MobileNav.css";

const MobileNav = ({ isOpen, setIsOpen }) => {
  const [subMenu, setSubMenu] = useState("");

  const openSubMenu = (section) => {
    setSubMenu(section);
  };

  return (
    <div
      className={`MobileNav ${isOpen ? "mobile-nav-open" : "mobile-nav-close"}`}
    >
      <div className="mobile-nav-close-btn" onClick={() => setIsOpen(!isOpen)}>
        <div
          className="bar"
          style={{
            transform: "rotate(45deg) translateX(40px) translateY(-40px)",
          }}
        ></div>
        <div
          className="bar"
          style={{
            transform: "rotate(-45deg) translateX(11px) translateY(11px)",
          }}
        ></div>
      </div>
      <div className="mobile-nav-item">
        <Link to="/">
          <p className="mobile-logo">EFP Capital</p>
        </Link>
      </div>
      <div className="mobile-nav-item">
        <div onClick={() => openSubMenu("about")} className="expand-submenu">
          <p>About</p>
          <img
            src="/images/arrow.png"
            alt="Chevron"
            className={`${subMenu === "about" && "arrow-open"}`}
          />
        </div>
        <div
          className={`mobile-nav-submenu ${
            subMenu === "about" ? "mobile-nav-submenu-open" : ""
          }`}
        >
          <Link to="/about">
            <p>Overview</p>
          </Link>
          <Link to="/about/selected-transactions">
            <p>Selected Transactions</p>
          </Link>
          <Link to="/about/selected-managers">
            <p>Selected Managers</p>
          </Link>
          <Link to="/about/selected-investments">
            <p>Selected Investments</p>
          </Link>
        </div>
      </div>
      <div className="mobile-nav-item">
        <div onClick={() => openSubMenu("team")} className="expand-submenu">
          <p>Team</p>
          <img
            src="/images/arrow.png"
            alt="Chevron"
            className={`${subMenu === "team" && "arrow-open"}`}
          />
        </div>
        <div
          className={`mobile-nav-submenu ${
            subMenu === "team" ? "mobile-nav-submenu-open" : ""
          }`}
        >
          <NavLink to="/team#Paul-Daniel">
            <p>Paul Daniel</p>
          </NavLink>
          <NavLink to="/team#Bart-Homan">
            <p>Bart Homan</p>
          </NavLink>
          <NavLink to="/team#Jim-Ryall">
            <p>Jim Ryall</p>
          </NavLink>
        </div>
      </div>
      <div className="mobile-nav-item">
        <Link to="/contact">
          <p>Contact</p>
        </Link>
      </div>
    </div>
  );
};

export default MobileNav;
