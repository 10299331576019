import React from "react";
import Layout from "../components/Layout/Layout";
import "./SelectedManagers.css";

const SelectedManagers = () => {
  return (
    <div className="SelectedManagers">
      <Layout>
        <div
          className="hero"
          style={{
            backgroundImage: "url('/images/managers.jpg')",
          }}
        >
          <div className="cover">
            <div className="cover-content">
              <h2>Long Term Relationships with Well Established Managers</h2>
            </div>
          </div>
        </div>
        <div className="content">
          {/* <div className="content" style={{ backgroundColor: "#fff" }}> */}
          <h2>Selected Managers</h2>
          <div className="managers-logos">
            <a
              href="http://www.admcapital.com/"
              rel="noreferrer"
              target="_blank"
            >
              <img src="/images/managers/adm-capital.png" alt="ADM Capital" />
            </a>
            <a href="http://www.ceecat.com/" rel="noreferrer" target="_blank">
              <img src="/images/managers/ceecat2.png" alt="Ceecat" />
            </a>
            <a
              href="http://www.cheynecapital.com/"
              rel="noreferrer"
              target="_blank"
            >
              <img
                src="/images/managers/cheyne-capital.png"
                alt="Cheyne Capital"
                className="cheyne"
              />
            </a>
            <a
              href="http://www.cibusfund.com/"
              rel="noreferrer"
              target="_blank"
            >
              <img src="/images/managers/cibus-fund.png" alt="Cibus Fund" />
            </a>
            <a
              href="http://www.dncapital.com/"
              rel="noreferrer"
              target="_blank"
            >
              <img src="/images/managers/dc-capital.svg" alt="DC Capital" />
            </a>
            <a href="http://www.extantia.com/" rel="noreferrer" target="_blank">
              <img
                className="extantia"
                src="/images/managers/extantia.jpg"
                alt="Extantia"
              />
            </a>
            <a
              href="http://www.hanoverinvestors.com/"
              rel="noreferrer"
              className="hanover"
              target="_blank"
            >
              <img src="/images/managers/hanover.png" alt="Hanover" />
            </a>
            <a
              href="https://www.healpartners.com"
              rel="noreferrer"
              target="_blank"
            >
              <img
                src="/images/managers/healpartners.png"
                alt="Heal Partners"
              />
            </a>
            <a
              href="http://www.quantumexp.co.uk/"
              rel="noreferrer"
              target="_blank"
            >
              <img
                src="/images/managers/quantum-exponential.png"
                alt="Quantum Exponential"
              />
            </a>
            <a href="http://www.raine.com/" rel="noreferrer" target="_blank">
              <img src="/images/managers/raine.png" alt="Raine" />
            </a>
            <a href="https://scytale.ventures" rel="noreferrer" target="_blank">
              <img
                src="/images/managers/scytale-ventures.svg"
                alt="Scytale Ventures"
              />
            </a>
            <a href="http://www.swayvc.com/" rel="noreferrer" target="_blank">
              <img
                src="/images/managers/sway-ventures.png"
                alt="Sway Ventures"
              />
            </a>
            <a
              href="http://www.valiance-am.com/"
              rel="noreferrer"
              target="_blank"
            >
              <img src="/images/managers/valiance.png" alt="Valiance" />
            </a>
          </div>
        </div>
      </Layout>
    </div>
  );
};

export default SelectedManagers;
