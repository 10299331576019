import React from "react";
// import { NavLink } from "react-r,outer-dom";
import { HashLink as NavLink } from "react-router-hash-link";
import "./Submenu.css";

const Submenu = ({ items }) => {
  return (
    <div className="Submenu">
      {items.map((item) => (
        <NavLink to={`/${item.url}`} key={item.name}>
          <li>{item.name}</li>
        </NavLink>
      ))}
    </div>
  );
};

export default Submenu;
