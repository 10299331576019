import React from "react";
import Layout from "../components/Layout/Layout";
import "./Contact.css";

const Contact = () => {
  return (
    <div className="Contact">
      <Layout>
        <div className="content">
          <iframe
            title="Contact"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7479.055290440521!2d-0.21808946270116195!3d51.48884262607891!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48760fbe72adbd71%3A0x770563b8f9f1553e!2sPalliser%20House%2C%20Palliser%20Rd%2C%20London%20W14%209EB!5e0!3m2!1sen!2suk!4v1685963541191!5m2!1sen!2suk"
            width="100%"
            height="450"
            style={{ border: 0 }}
            allowfullscreen=""
            loading="lazy"
            id="map"
          ></iframe>
          <div className="contact-info">
            <div>
              <h3>EFP Capital LLP</h3>
              <li>Palliser House</li>
              <li>Palliser Road</li>
              <li>London W14 9EB</li>
              <li>United Kingdom</li>
              <br />
              <li>
                Tel: <a href="tel:+44(0)2072019250">+44 (0) 207 2019250</a>
              </li>
              <li>Fax: +44 (0) 207 2019258</li>
              <li>
                Email:{" "}
                <a href="mailto:info@efpcapital.com">info@efpcapital.com</a>
              </li>
              <li>
                Web:{" "}
                <a
                  href="https://www.efpcapital.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  www.efpcapital.com
                </a>
              </li>
            </div>
            <div>
              <h3>EFP Office Management</h3>
              <p>KATIE INGLE-FINCH</p>
              <p>
                Tel: <a href="tel:+44(0)2072019250">+44 (0) 207 2019250</a>
              </p>
              <p>
                Email:{" "}
                <a href="mailto:katie.ingle-finch@efpcapital.com">
                  katie.ingle-finch@efpcapital.com
                </a>
              </p>
            </div>
            <div>
              <h3>EFP Office - Directions</h3>
              <p>
                For meetings at EFP Capital please enter Palliser House which is
                located by the entrance to The Queen's Club on Palliser Road.
              </p>
              <p>
                EFP offices are located on the Ground Floor to the right on
                entering Palliser House.
              </p>
            </div>
          </div>
        </div>
      </Layout>
    </div>
  );
};

export default Contact;
