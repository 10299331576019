import React from "react";
import Layout from "../components/Layout/Layout";

const SelectedInvestments = () => {
  return (
    <div className="SelectedInvestments">
      <Layout>
        <div
          className="hero"
          style={{ backgroundImage: "url('/images/investments.jpg')" }}
        >
          <div className="cover">
            <div className="cover-content">
              <h2>
                Investment in Early Stage Opportunities including EIS and SEIS
              </h2>
            </div>
          </div>
        </div>
        <div className="content">
          <h2>Selected Investments</h2>
          <div className="managers-logos">
            <a
              href="http://www.amicala.co.uk/"
              rel="noreferrer"
              target="_blank"
            >
              <img src="/images/investments/amicala.svg" alt="Amical" />
            </a>
            <a
              href="http://www.bindingsolutions.uk/"
              rel="noreferrer"
              target="_blank"
            >
              <img
                src="/images/investments/bindingsolutions.png"
                alt="Binding Solutions"
              />
            </a>
            <a
              href="https://www.bluecedar.com/"
              rel="noreferrer"
              className="bluecedar"
              target="_blank"
            >
              <img src="/images/investments/bluecedar.svg" alt="Blue Cedar" />
            </a>
            <a
              href="https://www.century.tech/"
              rel="noreferrer"
              target="_blank"
            >
              <img src="/images/investments/century.png" alt="Century" />
            </a>
            <a
              href="https://www.custodian.club/"
              rel="noreferrer"
              target="_blank"
            >
              <img src="/images/investments/custodian.svg" alt="Custodian" />
            </a>
            <a href="https://diid.co/" rel="noreferrer" target="_blank">
              <img
                className="diid"
                src="/images/investments/diid.svg"
                alt="diid"
              />
            </a>
            <a
              href="http://www.doctime.com.bd/"
              rel="noreferrer"
              target="_blank"
            >
              <img src="/images/investments/doctime.svg" alt="Doc Time" />
            </a>
            <a
              href="http://www.easymoney.com/"
              rel="noreferrer"
              target="_blank"
            >
              <img src="/images/investments/easymoney.png" alt="Easy Money" />
            </a>
            <a
              href="http://www.easystorage.com/"
              rel="noreferrer"
              target="_blank"
            >
              <img
                src="/images/investments/easystorage.png"
                alt="Easy Storage"
              />
            </a>
            <a
              href="https://www.evoralis.com/"
              rel="noreferrer"
              target="_blank"
            >
              <img src="/images/investments/evoralis.png" alt="Evoralis" />
            </a>
            <a
              href="http://www.gpnutrition.com/"
              rel="noreferrer"
              target="_blank"
            >
              <img
                src="/images/investments/gpnutrition.png"
                alt="GP Nutrition"
              />
            </a>
            <a
              href="http://www.greenback.earth/"
              rel="noreferrer"
              target="_blank"
            >
              <img src="/images/investments/greenback.png" alt="Greenback" />
            </a>
            <a href="http://www.glhvideo.com/" rel="noreferrer" target="_blank">
              <img
                src="/images/investments/growthleasing.png"
                alt="Growth Leasing"
              />
            </a>
            <a href="https://hotelmap.com/" rel="noreferrer" target="_blank">
              <img
                className="hotelmap"
                src="/images/investments/hotelmap.svg"
                alt="hotelmap"
              />
            </a>
            <a href="https://www.hybr.co.uk/" rel="noreferrer" target="_blank">
              <img src="/images/investments/hybr.png" alt="hybr" />
            </a>
            <a href="https://www.inkwrx.com/" rel="noreferrer" target="_blank">
              <img src="/images/investments/inkwrx.svg" alt="inkwrx" />
            </a>
            <a
              href="http://www.plasticenergy.com/"
              rel="noreferrer"
              className="plasticenergy"
              target="_blank"
            >
              <img
                src="/images/investments/plasticenergy.png"
                alt="Plastic Energy"
              />
            </a>
            <a href="https://silatha.com/" rel="noreferrer" target="_blank">
              <img src="/images/investments/silatha.png" alt="Silatha" />
            </a>
            <a
              href="http://www.velocity.black/"
              rel="noreferrer"
              target="_blank"
            >
              <img
                src="/images/investments/velocityblack.svg"
                alt="Velocity Black"
                className="velocity"
              />
            </a>
            <a href="https://vollou.com" rel="noreferrer" target="_blank">
              <img
                className="vollou"
                src="/images/investments/vybz.png"
                alt="Vybz"
              />
            </a>
          </div>
        </div>
      </Layout>
    </div>
  );
};

export default SelectedInvestments;
