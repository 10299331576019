import React from "react";
import Layout from "../components/Layout/Layout";

const About = () => {
  return (
    <div className="About">
      <Layout>
        <div
          className="hero"
          style={{ backgroundImage: "url('/images/about.jpg')" }}
        >
          <div className="cover">
            <div className="cover-content">
              <h2>
                Long History of Financial Structuring, Investment and Capital
                Raising
              </h2>
            </div>
          </div>
        </div>
        <div className="content">
          <h3>Overview</h3>
          <li>
            EFP Capital LLP ("EFP") is an advisory boutique specialising in
            structuring of bespoke alternative products for institutional and
            private clients.
          </li>
          <li>
            EFP partners provide seed capital and invest in all the structured
            products, funds and early stage opportunities we work with.
          </li>
          <li>
            In addition, we support both new and established fund managers as
            well as start-ups in all stages of capital raising.
          </li>
          <li>
            EFP is committed to enhancing investors' risk/reward profiles as
            well as optimising the underlying fee structures.
          </li>
          <li>
            EFP does not charge fees to investors, but is remunerated by the
            fund managers. To ensure alignment of interest with investors, we
            are always paid on a trail basis.
          </li>
          <li>
            EFP selects funds and investment opportunities primarily on a
            qualitative basis, working only with managers known professionally
            for many years.
          </li>
          <li>
            EFP has raised approximately $3 billion of capital for structured
            products and single funds since inception.
          </li>
          <li>
            Based in London, EFP was founded by Paul Daniel in 1999. EFP is
            authorised and regulated by the Financial Conduct Authority ("FCA").
          </li>
        </div>
      </Layout>
    </div>
  );
};

export default About;
