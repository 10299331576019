export const headerContent = {
  about: [
    {
      name: "Overview",
      url: "about",
    },
    {
      name: "Selected Transactions",
      url: "about/selected-transactions",
    },
    {
      name: "Selected Managers",
      url: "about/selected-managers",
    },
    {
      name: "Selected Investments",
      url: "about/selected-investments",
    },
  ],
  team: [
    {
      name: "Paul Daniel",
      url: "team#Paul-Daniel",
    },
    {
      name: "Bart Homan",
      url: "team#Bart-Homan",
    },
    {
      name: "Jim Ryall",
      url: "team#Jim-Ryall",
    },
  ],
};
