import React from "react";
import Layout from "../components/Layout/Layout";
import "./Team.css";

const Team = () => {
  return (
    <div className="Team">
      <Layout>
        <div
          className="hero"
          style={{
            backgroundImage: "url('/images/team.jpg')",
            height: "86vh",
          }}
        >
          <div className="cover">
            <div className="cover-content">
              <h2>
                Over 100 Years of combined Experience in the Financial Sector
              </h2>
            </div>
          </div>
        </div>
        <div className="content">
          <div className="team-main">
            <div id="Paul-Daniel" className="partner">
              <img src="/images/paul.jpg" alt="Paul Daniel" />
              <h4>PAUL DANIEL</h4>
              <p>
                Paul Daniel has over 40 years experience in Investment Banking.
                He spent 15 years at Morgan Stanley, where he was latterly
                Co-Head of Global Derivatives and Head of European Fixed Income.
                Paul founded EFP in 1999.
              </p>
            </div>
            <div id="Bart-Homan" className="partner">
              <img src="/images/bart.jpg" alt="Bart Homan" />
              <h4>BART HOMAN</h4>
              <p>
                Bart Homan has over 35 years experience in Investment and
                Private Banking as well as alternative investments. He spent 12
                years at Morgan Stanley, mainly in Investment Banking and
                latterly in their Private Banking Division. Prior to Morgan
                Stanley, Bart worked in Investment Banking for Lazard and
                Prudential-Bache. Bart joined EFP in 2002.
              </p>
            </div>
            <div id="Jim-Ryall" className="partner">
              <img src="/images/jim.jpg" alt="Jim Ryall" />
              <h4>JIM RYALL</h4>
              <p>
                Jim Ryall has 35 years of experience in investment banking and
                alternative investments. He spent 9 years with the Flemings
                Group in London and the Far East, before focusing on alternative
                assets. He latterly established Hamilton Lane's private equity
                presence in London where he was CEO and director responsible for
                marketing. Jim joined EFP in 2005.
              </p>
            </div>
          </div>
        </div>
      </Layout>
    </div>
  );
};

export default Team;
