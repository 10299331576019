import React, { useState } from "react";
import { NavLink, Link } from "react-router-dom";
import Submenu from "../Submenu/Submenu";
import { headerContent } from "./headerContent";
import MobileNav from "../MobileNav/MobileNav";
import "./Header.css";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="Header">
      <div className="left-hamburger"></div>
      <Link to="/">
        <h1 className="logo">EFP Capital LLP</h1>
      </Link>
      {/* hamburger menu */}
      <div className="hamburger-menu" onClick={() => setIsOpen(!isOpen)}>
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
      </div>
      <div className="navigation">
        <div className="nav-item">
          <NavLink to="/about">
            <li>About</li>
          </NavLink>
          <span className="sub-menu">
            <Submenu items={headerContent["about"]} />
          </span>
        </div>
        <div className="nav-item">
          <NavLink to="/team">
            <li>Team</li>
          </NavLink>
          <span className="sub-menu">
            <Submenu items={headerContent["team"]} />
          </span>
        </div>
        <div className="nav-item">
          <NavLink to="/contact">
            <li>Contact</li>
          </NavLink>
        </div>
      </div>
      <MobileNav isOpen={isOpen} setIsOpen={setIsOpen} />
    </div>
  );
};

export default Header;
