import React from "react";
import Layout from "../components/Layout/Layout";

const Compliance = () => {
  return (
    <div className="Compliance">
      <Layout>
        <div
          className="hero"
          style={{ backgroundImage: "url('/images/about.jpg')" }}
        >
          <div className="cover"></div>
        </div>
      </Layout>
    </div>
  );
};

export default Compliance;
